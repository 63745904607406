<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>赛事列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="赛事名称">
                                <el-input v-model="search.activity_title"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="发布状态">
                                <el-select v-model="search.publish_state" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="已发布" :value=1></el-option>
                                    <el-option label="未发布" :value=0></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="审核状态">
                                <el-select v-model="search.examine_status" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="审核中" :value="0"></el-option>
                                    <el-option label="审核通过" :value="1"></el-option>
                                    <el-option label="审核失败" :value="2"></el-option>
                                    <el-option label="违规下架" :value="3"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="运动品类">
                                <el-input v-model="search.motion_category_two"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="运动效果">
                                <el-input v-model="search.target_category_zero"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="时间">
                                <el-select v-model="search.time_type" style="width: 30%">
                                    <el-option label="报名开始时间" value="signup_start_time"></el-option>
                                    <el-option label="赛事开始时间" value="start_time"></el-option>
                                </el-select>
                                <el-date-picker style="width: 70%" v-model="searchtime" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('product.contest.issave')" icon="el-icon-plus" size="medium" type="primary"
                            @click="tosee()">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column prop="activity_title" label="赛事名称" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="activity_title" label="赛事类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{ scope.row.contest_type === 1 ? '个人赛' : '团队赛' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="originally_price" label="报名人数/人数上限">
                    <template slot-scope="scope">
                        <span>{{ scope.row.bookings_sum }}</span>
                        <span>/{{ scope.row.quota }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="coupon_activity_qrcode" label="赛事二维码" width="100">
                    <template slot-scope="scope">
                        <viewer v-if="!is_empty(scope.row.activity_qrcode)">
                            <el-avatar shape="square" :size="22" :src="scope.row.activity_qrcode"></el-avatar>
                        </viewer>
                        <span v-else>-</span>
                    </template>
                </el-table-column> -->
                <el-table-column prop="signup_end_time" label="报名截止时间" width="120" show-overflow-tooltip>
                </el-table-column>

                <el-table-column prop="originally_price" label="赛事地址" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.county }}{{ scope.row.road }}{{ scope.row.address }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="detailed_time" label="赛事时间" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-popover v-if="scope.row.time_type === 2" placement="right" width="200" trigger="click">
                            <el-table :data="scope.row.detailed_time">
                                <el-table-column width="205" property="date" label="赛事时间">
                                    <template slot-scope="scope2">
                                        {{ scope2.row.activity_date }}
                                        ({{ scope2.row.activity_time[0] }}至{{ scope2.row.activity_time[1] }})
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button type="text" size="mini" slot="reference">
                                查看，共{{ scope.row.detailed_time.length }}天
                            </el-button>
                        </el-popover>
                        <span v-else-if="scope.row.time_type === 3">
                            {{ scope.row.start_time }}至{{ scope.row.end_time }}
                        </span>
                        <span v-else>
                            {{ scope.row.detailed_time[0].activity_date }}
                            ({{
                                scope.row.detailed_time[0].activity_time[0]
                            }}至{{ scope.row.detailed_time[0].activity_time[1] }})
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="motion_category_two" label="运动品类" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :key="item.id" v-for="item in scope.row.motion_category_two" class="form-tag_colour-tag"
                            effect="dark" size="mini">
                            {{ item.name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="target_category_zero" label="运动效果" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :key="item.id" v-for="item in scope.row.target_category_zero" class="form-tag_colour-tag"
                            effect="dark" size="mini" :color="item.target_category_zero">
                            {{ item.name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="originally_price" label="售价">
                    <template slot-scope="scope">
                        <span>￥{{ scope.row.price }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="duration" label="状态">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state_x === 4" type="info">{{ scope.row.state_name }}</el-tag>
                        <el-popover v-else-if="scope.row.state_x === 5" placement="right" width="200" trigger="click">
                            <div>
                                <div>操作方：
                                    <span v-if="scope.row.withdraw_type === 'oss'">平台管理员</span>
                                    <span v-if="scope.row.withdraw_type === 'merchant'">商户端</span>
                                </div>
                                <div>下架时间：{{ scope.row.withdraw_time }}</div>
                                <div>下架原因：{{ scope.row.withdraw_remarks }}</div>
                            </div>
                            <el-tag size="mini" type="danger" slot="reference">{{
                                scope.row.state_name
                            }}
                            </el-tag>
                        </el-popover>
                        <el-tag size="mini" v-else-if="scope.row.state_x === 6" type="warning">{{
                            scope.row.state_name
                        }}
                        </el-tag>
                        <el-tag size="mini" v-else type="success">{{ scope.row.state_name }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="examine_status" label="审核状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.examine_status === 0">审核中</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 1" type="success">审核通过</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 2" type="danger">审核失败</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 3" type="danger">违规下架</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="is_refund" label="允许退款" width="250" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.is_refund === 1">是：退款截止时间{{
                            scope.row.is_refund_end_time
                        }}
                        </el-tag>
                        <el-tag size="mini" v-if="scope.row.is_refund === 0" type="warning">否</el-tag>
                    </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="400">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('order.contest.getlist')" @click="order_page(scope.row)" size="mini">
                            查看订单
                        </el-button>
                        <el-button v-if="is_auth('product.contest.getinfo')"
                            @click="tosee(scope.row.activity_uuid, 'watch')" size="mini">查看
                        </el-button>
                        <el-button v-if="is_auth('product.contest.issave')" @click="tosee(scope.row.activity_uuid, 'edit')"
                            size="mini">编辑
                        </el-button>
                        <el-button v-if="is_auth('product.contest.upapply') && scope.row.publish_state === 0 &&
                            scope.row.examine_status === 1 && scope.row.canceled_at === 0"
                            @click="operation_tip(scope.row.activity_uuid, scope.row.activity_title, 'upapply')"
                            type="success" size="mini">上架发布
                        </el-button>
                        <el-button
                            v-if="is_auth('product.contest.withdraw') && scope.row.publish_state === 1 && scope.row.canceled_at === 0"
                            @click="operation_tip(scope.row.activity_uuid, scope.row.activity_title, 'withdraw')"
                            type="warning" :disabled="scope.row.lesson_state === 3" size="mini">取消发布
                        </el-button>
                        <el-popover placement="left" width="80" trigger="click" style="margin-left: 12px;">
                            <div>
                                <el-button v-if="is_auth('product.contest.iscancel')"
                                    style="width:100%;display: block;margin-left: 0;margin-bottom: 12px;"
                                    :disabled="scope.row.lesson_state === 3 || scope.row.canceled_at !== 0"
                                    @click="iscancel_tip(scope.row.activity_uuid, scope.row.activity_title)" type="danger"
                                    size="mini">取消赛事
                                </el-button>
                                <el-button style="width:100%;display: block;margin-left: 0;margin-bottom: 12px;"
                                    v-if="is_auth('product.contest.isdel')"
                                    @click="operation_tip(scope.row.activity_uuid, scope.row.activity_title, 'isdel')"
                                    type="danger" :disabled="scope.row.is_dels > 0" size="mini">删除
                                </el-button>
                                <el-button style="width:100%;display: block;margin-left: 0;margin-bottom: 12px;"
                                    type="danger"
                                    :disabled="scope.row.examine_status !== 3 && scope.row.examine_status !== 2" size="mini"
                                    @click="operation_tip(scope.row.examine_reason, scope.row.activity_title, 'watch')">查看原因
                                </el-button>
                            </div>
                            <el-button size="mini" type="primary" slot="reference">更多
                            </el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>
        </div>
        <!--订单内容-->
        <el-dialog v-loading="loading" v-if="is_auth('order.contest.getlist')" title="赛事订单" :visible.sync="order_dialog"
            width="70%" :close-on-press-escape="false" :close-on-click-modal="false">
            <el-row>
                <OrderList ref="order_list" />
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
import OrderList from './page/OrderList'
export default {
    components: {
        OrderList
    },
    data() {
        return {
            page_name: '赛事列表',
            loading: true,      // 加载状态
            issearch: true,    // 搜索是否展示
            order_dialog: false,    // 搜索是否展示
            search: {},         // 搜索内容
            searchtime: null,    // 时间条件
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
        }
    },
    // 创建
    created() {
        this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        time_decide_tool(time, decide = '<', time2 = 'now') {
            if (typeof time === "string") {
                let date1 = new Date(time.replace(/-/g, "/")).getTime()        // 判断时间
                let date2 = new Date().getTime()            // 当前时间
                if (time2 !== 'now') {
                    date2 = new Date(time2.replace(/-/g, "/")).getTime()       // 判断时间2
                }
                if (decide === '>') {
                    if (date1 > date2) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    if (date1 < date2) {
                        return true
                    } else {
                        return false
                    }
                }
            }
        },
        // 展示订单
        order_page(activity_info) {
            this.order_dialog = true
            // console.log('activity_info', activity_info)
            let thi = this
            setTimeout(function () {
                thi.$refs.order_list.init(activity_info)
            }, 300);
        },
        // 判断是否为空
        time_decide(time, decide = '<',) {
            return this.time_decide_tool(time, decide)
        },     // 判断是否为空
        is_empty(data) {
            return this.Tool.is_empty(data)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                activity_title: '',
                publish_state: '',
                target_category_zero: '',
                motion_category_two: '',
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "product.contest.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0]
                postdata.endtime = this.searchtime[1]
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(activity_uuid = '', title = '', operation = '') {

            let tip = ''
            let options = {}
            // 发布
            if (operation === 'upapply') {
                tip = '上架【' + title + '】？'
            }
            // 下架
            if (operation === 'withdraw') {
                tip = '<p style="text-align:center">取消发布【' + title + '】？</p><p style="color:red;text-align:center">（取消发布后，该活动信息在用户端小程序不可见哦～）</p>'
                options["dangerouslyUseHTMLString"] = true;
            }
            // 删除
            if (operation === 'isdel') {
                tip = '删除【' + title + '】？'
            }

            // 查看原因
            if (operation === 'watch') {
                tip = activity_uuid
            }
            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    if (operation !== 'watch') {
                        this.isoperation(activity_uuid, operation)
                    }
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(activity_uuid = '', operation = '') {
            let postdata = {
                api_name: "product.contest." + operation,
                token: this.Tool.get_l_cache('token'),
                activity_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 下架（取消）
        iscancel_tip(activity_uuid = 'x', title = '') {
            this.$prompt('请输入取消理由<span style="color:red">（若取消赛事，则已报名的用户会全部退款哦～）</span>', '确定取消【' + title + '】赛事?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                dangerouslyUseHTMLString: true
            })
                .then(({ value }) => {
                    this.iscancel(activity_uuid, value)
                })
                .catch(() => {
                });
        },
        iscancel(activity_uuid, value) {
            let postdata = {
                api_name: "product.contest.iscancel",
                token: this.Tool.get_l_cache('token'),
                activity_uuid,
                withdraw_remarks: value,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 显示/隐藏
        isshow_tip(activity_uuid = 'x', title = '', state = 0) {
            let tip = ''
            let options = {}
            if (state === 1) {
                tip = '显示【' + title + '】？'
            } else if (state === 3) {
                tip = '隐藏【' + title + '】？'
            }
            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    this.isshow(activity_uuid, state)
                })
                .catch(() => {
                });
        },
        isshow(activity_uuid = 'x', state = 0) {
            let postdata = {
                api_name: "product.activity.isshow",
                token: this.Tool.get_l_cache('token'),
                activity_uuid,
                state,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(activity_uuid = "", type) {
            if (activity_uuid === '') {
                this.$router.push({ path: '/product/contest/edit' })
            } else {
                if (type === 'watch') {
                    this.$router.push({ path: '/product/contest/info', query: { activity_uuid } })
                } else {
                    this.$router.push({ path: '/product/contest/edit', query: { activity_uuid } })
                }
            }
        },


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-tag_colour-div {
    min-height: 200px;
}
</style>
